import React, {Component} from 'react';
import { Container, Row, Col} from 'reactstrap';
import Home from './Home.js';
import NewHome from './NewHome.js';
import Details from './Details.js';
import WhenWhere from './WhenWhere.js';
import LocalFun from './LocalFun.js';
import Buddymoon from './Buddymoon.js';
import NavbarCustom from './Navbar.js';
import NewNavbarCustom from './NewNavbar.js';
import SaveTheDate from './SaveTheDate.js';
import RSVP from './RSVP.js';
import Registry from './Registry.js';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
    
class App extends Component {
    
    constructor(props){
        super(props);

        this.state={
        }
    }
    
    render() {

                 
        
  return (
       <div className="App">
            <Router>
       
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>         
            <Switch>
                <Route path="/recap">
                    <NewNavbarCustom/>
                    <NewHome/>
                </Route>
                <Route path="/home">
                    <NavbarCustom/>
                    <Home/>
                </Route>
                <Route path="/whenandwhere">
                    <NavbarCustom/>
                    <WhenWhere/>
                </Route>
                <Route path="/details">
                    <NavbarCustom/>
                    <Details/>
                </Route>
                <Route path="/localfun">
                    <NavbarCustom/>
                    <LocalFun/>
                </Route>
                 <Route path="/buddymoon">
                    <NavbarCustom/>
                    <Buddymoon/>
                </Route>
                <Route path="/rsvp">
                    <NavbarCustom/>
                    <RSVP/>
                </Route>
                <Route path="/registry">
                    <NavbarCustom/>
                    <Registry/>
                </Route>
                <Route path="/savethedate">
                    <NavbarCustom/>
                    <SaveTheDate/>
                </Route>
                <Route exact path="/">
                    <NewNavbarCustom/>
                    <NewHome/>
                </Route>
                 <Route path="*">
                    <NavbarCustom/>
                    <Container style={{marginBottom:"4.25vh"}}>
                        <Row>
                            <Col md="3" xs="1">
                            </Col>
                            <Col md="6" xs="10">
                                <div>
                                      <h5>"The meaning of life is to have fun and help other people have fun."</h5>
                                </div>
                            </Col>

                        </Row>
                      </Container>                    
                  </Route>
            </Switch>
    </Router>
</div>
    );
  }
}

export default App;
