import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col} from 'reactstrap';
import monogram from './img/eccs-monogram-horizontal.svg';

import hallIllo from './img/hall-illo.svg';
import calendar from './img/calendar.png';
import edmontonKey from './img/edmonton-key.svg';
import gmapToner from './img/gmap-toner.png';

class WhenWhere extends Component {
    
    constructor(props){
        super(props);

        this.state={
            
        }
    }
    
    render() {
    
  return (
      
    <div className="WhenWhere">
      
         
      <Container className="bottom-spacer">
        <Row>
            <Col xs="12" md="6">
                    <div className="h1-XXL yellow">When & Where</div>
            </Col>
            <Col xs="12" md="6">
                     <img src={hallIllo} style={{width:"100%", top:"-15px"}} alt="Illustration of Hall"/>
            </Col>
        </Row>
      </Container>
      
      <Container>
        <Row>
            <Col className="bottom-spacer" md="4">
                <h5 className="sigLine">
                    Cloverdale Community League
                <br/>
                    9411 97 Ave NW, Edmonton Alberta
                </h5>
            </Col>
            <Col className="bottom-spacer" md="4">
                <h5 className="sigLine">
                    Sunday, September 4, 2022 
                <br/>
                    Ceremony begins at 3:30 PM 
                </h5>
            </Col>
        </Row>
    </Container>
      
      
    <Container className="bottom-spacer-x2">
        <Row>
            <Col xs={{size: 12}}>
                <a target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://goo.gl/maps/3LTYUpG7UXwo3Q7Z8">
                    <img src={gmapToner} style={{width:"100%"}} alt="Static Google Map"/>
                </a>
                <a 
                        target="_blank" 
                        rel="noopener noreferrer"
                        href="https://goo.gl/maps/3LTYUpG7UXwo3Q7Z8" 
                        style={{width:"100%"}} 
                        className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>OPEN IN GOOGLE MAPS
                    </a>
            </Col>
      </Row>
      {/*
            <Col xs={{size:10, offset: 1}} md={{size:5, offset:0}}>
                <img src={calendar} style={{width:"100%"}} alt="Calendar"/>
            </Col>
        
    */}
    </Container>
            
    <Container>
      
        <Row className="bottom-spacer">
            <Col xs="6" s="2">
                <h3>Where to Stay</h3>
            </Col>
            <Col xs="6" s="10"/>
        </Row>
      
        <Row>
      
            <Col md="2" className="d-none d-lg-block d-xl-block" style={{alignSelf: "flex-end"}}>
                <div>
                    <img src={edmontonKey} alt="Hotel Key Illustration"/>
                </div>
            </Col>
      
            <Col    className="bottom-spacer"
                    xs={{ size: 10, order: 2, offset: 2}} 
                    md={{size: 4, order: 2, offset:2}} 
                    lg={{size: 4, order: 2, offset:0}}>
                <h2>Varscona Hotel</h2>
                <p>
                    A classic and casually sophisticated hotel on Whyte Avenue.   
                </p>
                    <a 
                        href="https://www.varscona.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>VARSCONA
                    </a>
            </Col>
            <Col    className="bottom-spacer"
                    xs={{ size: 10, order: 2, offset: 2}}
                    md={{ size: 4, order: 2, offset:2}}>
                <h2>Meterra Hotel</h2>
                <p>
                    A boutique hotel on Whyte Avenue.  
                </p>
                    <a 
                        href="https://www.metterra.com/" 
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>METERRA
                    </a>
                    <br />
            </Col>
        </Row>
      
        <Row>
      
            <Col    className="bottom-spacer" 
                    xs={{ size: 10, order: 2, offset: 2}}
                    md={{ size: 4, order: 2, offset:2}}>
                <h2>Campus Tower</h2>
                <p>
                    Suites with kitchenettes and living rooms in the University neighbourhood.
                </p>
                <a 
                    href="https://www.campustower.com/" 
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{width:"100%"}} 
                    className="yellow btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>CAMPUS TOWER
                </a>
            </Col>
      
            <Col    className="bottom-spacer"
                    xs={{ size: 10, order: 2, offset: 2}}
                    md={{ size: 4, order: 2, offset:2}}>
                <h2>Airbnb</h2>
                <p>
                    Apartments, basement suites, and home rentals available on Airbnb. Neighbourhoods to search for include Old Strathcona, Cloverdale, Riverdale, Ritchie, and Garneau.
                </p>
                <a 
                    href="https://www.airbnb.ca/s/Cloverdale--Edmonton--AB--Canada/homes?adults=1&children=0&checkin=2020-08-28&checkout=2020-08-30&source=mc_search_bar&refinement_paths%5B%5D=%2Fhomes&place_id=ChIJmTDt8V0ioFMROKE1Dccnjjk" 
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{width:"100%"}} 
                    className="yellow btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>AIRBNB
                </a>
            </Col>
      
        </Row>
    </Container>
      
    <Container>
      
       <Row className="bottom-spacer">
            <Col xs="6" s="2">
                <h3>Parking</h3>
            </Col>
            <Col xs="6" s="10"/>
        </Row>
      
        <Row className="bottom-spacer">
                <Col xs="2"/>
                <Col xs="7">
                    <p>
                        Free parking is available at the Hall and at the Muttart Conservatory lot. If you have mobility restrictions, please let us know and we will reserve a parking spot for you.
                    </p>
                </Col>
                <Col xs="3"/>
        </Row>
    </Container>
      
      
   <Container fluid style={{backgroundColor:"white"}}>
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs={{size:4, offset: 4, order: 2}} md={{size: 2, offset: 5, order: 2}}>
                       <img className="center" style={{width: "70%"}} src={monogram} alt="EC + CS Monogram"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>

      
    </div>
      
    );
  }
}

export default WhenWhere;