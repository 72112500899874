import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col} from 'reactstrap';
import monogram from './img/eccs-monogram-horizontal.svg';
import kissBike from './img/kissbike-illo.svg';
import eccsPhoto from './img/eccs-photo.jpg';
import tinyHeart from './img/tiny-heart.svg';
import betterTogether from './img/better-together.svg';
import pinwheel from './img/pinwheel.svg';
import sparkles from './img/sparkles.svg';

//import ModalExample from './Modal.js';


class Home extends Component {
    
    constructor(props){
        super(props);

        this.state={

        }
    }
        
    
    render() {

    
  return (
    <div className="Home">
     
      {/*
      Container>
        <Row>
          <Col xs="12">
            <ModalExample/>
             </Col>
          </Row>
      </Container>
      */}
      
      <Container style={{marginBottom:"4.25vh"}}>
        <Row>
            <Col md="6" xs="12" className="hAlign">
                <div>
                    <div className="h1-XL yellow">Evelyn & Connor<br/> get married!</div>
                    <br/>
                    <h5>September 4, 2022</h5>
                    <h5>Cloverdale Community League<br/> Edmonton Alberta</h5>
                    <img src={tinyHeart} style={{width:"30px"}} alt="Tiny Heart Icon"/>
                </div>
            </Col>
            <Col md="6" xs="12">
                <img src={kissBike} style={{width:"100%"}} alt="Illustration Kiss Bike"/>
            </Col>
        </Row>
      </Container>
      
    <br /> 
      
    <Container className="bottom-spacer">
        <Row>
            <Col md={{ size: 4, order: 2, offset:1}} xs={{ size: 10, offset: 1}} className="hAlign">
                <div>
                    <h3>And you're invited!</h3>
                    <br/>
                    <p>Evelyn & Connor are (finally) getting married! We can’t wait to celebrate with you. We have created this website to help you plan and to share ideas for extra fun! Thank you for all your love and support.
                    </p>
                </div>
            </Col>
        
            <Col md={{ size: 6, order: 1, offset: 0}} xs={{ size: 10, offset: 1}}>
                <img src={eccsPhoto} style={{width: "100%"}} alt="Evelyn and Connor"/>
            </Col>
        </Row>
    </Container>
        
    <br />
        
    <Container style={{marginBottom:"4.25vh"}}>
        <Row>
            <Col xs={{size: 0}} md={{size: 2}} className="d-none d-md-block d-lg-block d-xl-block"  style={{alignSelf: "flex-end", marginBottom:"-25px"}}>
                <div>
                    <img src={pinwheel} alt="Pinwheel Illustration"/>
                </div>
            </Col>
            <Col md={{ size: 8}} xs={{ size: 12}}>
                        <div className="text-center">
                            <img src={sparkles} style={{width: "15%"}} alt="Sparkles Illustration"/>
                        </div>
                        <div className="poem">
                            They were friends, no matter the weather,<br/>
                            Truly two birds of a feather.<br/>
                            The boy and the girl, entered the world, <br/>
                            forever better together. <br/><br/>
                        </div>
                    
                        <a href="/whenandwhere" style={{width:"100%", display:"block"}} className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                              <span> </span>WHEN & WHERE</a>

            </Col>
            <Col xs={{size: 0}} md={{size: 2}} className="d-none d-md-block d-lg-block d-xl-block">
                <img src={betterTogether} alt="Better Together Icon"/>
            </Col>
        </Row>
    </Container>

    <Container fluid style={{backgroundColor:"white"}}>
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs={{size:4, offset: 4, order: 2}} md={{size: 2, offset: 5, order: 2}}>
                        <img className="center" style={{width: "70%"}} src={monogram} alt="EC + CS Monogram"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>

      
    </div>
      
    );
  }
}

export default Home;