
import React, {Component} from 'react';
import './App.css';
import { Navbar, NavbarBrand, NavItem, NavbarToggler, Collapse, Nav, Container} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap'

import eccsLogo from './img/eccs-logo.svg';


    
class NavbarCustom extends Component {
    
    constructor(props){
        super(props);

        this.state={
            isOpen: false
        }
    }
    
    render() {
             
        var activeStyle = {
                            fontWeight: "bold",
                            color: "#3aa7c1"
                          };
                 
  return (
    <Container fluid style={{backgroundColor:"white"}}>
     <Navbar light expand="md" className="container"  >
        <LinkContainer activeStyle={activeStyle} to="/recap">
            <NavbarBrand>
                <img src={eccsLogo} style={{width:"120px"}} alt="Evelyn and Connor Logo"/>
            </NavbarBrand>
        </LinkContainer>
        <NavbarToggler className="custom-toggler" id="navbar-toggle-custom" onClick={()=>{this.setState({isOpen:!this.state.isOpen})}} />

        <Collapse isOpen={this.state.isOpen} navbar>
             <Nav id="navbar-custom" className="mr-auto" navbar>
                 <LinkContainer activeStyle={activeStyle} to="/whenandwhere">
                    <NavItem onClick={()=>{this.setState({isOpen:!this.state.isOpen})}}>
                        WHEN & WHERE
                    </NavItem>
                </LinkContainer>

                <LinkContainer activeStyle={activeStyle} to="/details">
                    <NavItem onClick={()=>{this.setState({isOpen:!this.state.isOpen})}}>
                        DETAILS
                    </NavItem>
                </LinkContainer>

                <LinkContainer activeStyle={activeStyle} to="/localfun">
                    <NavItem onClick={()=>{this.setState({isOpen:!this.state.isOpen})}}>
                        LOCAL FUN
                    </NavItem>
                </LinkContainer>

                <LinkContainer activeStyle={activeStyle} to="/buddymoon">
                    <NavItem onClick={()=>{this.setState({isOpen:!this.state.isOpen})}}>
                         BUDDYMOON
                    </NavItem>
                </LinkContainer>

                <LinkContainer activeStyle={activeStyle} to="/registry">
                    <NavItem onClick={()=>{this.setState({isOpen:!this.state.isOpen})}}>
                         REGISTRY
                    </NavItem>
                </LinkContainer>

                <LinkContainer activeStyle={activeStyle} to="/rsvp">
                    <NavItem    onClick={()=>{this.setState({isOpen:!this.state.isOpen})}} 
                                className="btn-1">
                         RSVP
                    </NavItem>
                </LinkContainer>
            </Nav>
        </Collapse>
    </Navbar>
</Container>
    );
  }
}

export default NavbarCustom;
