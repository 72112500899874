import React, {Component} from 'react';
import { Container, Row, Col} from 'reactstrap';
import './App.css';
import moreSparkles from './img/moresparkles.svg';
    
class Registry extends Component {
    
    constructor(props){
        super(props);

        this.state={
        }
    }
    
    render() {   
        
  return (
        <div className="Registry">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      
                 <Container style={{marginBottom:"4.25vh"}}>
        <Row>
            <Col xs={{size:8, order: 1}}>
                    <div className="h1-XXL yellow">Registry</div>
            </Col>
        </Row>
        <Row>
            <Col xs={{size:10, order: 2, offset: 2}}>
            </Col>
        </Row>
      </Container>
      
    <br /> 

            <Container style={{marginBottom:"4.25vh"}}>      
        <Row style={{marginBottom:"3rem"}}>
                <Col md="5">
                    <p>
                        Joining us on our special day is the best gift we can ask for. We understand that many of our guests have significant costs related to travel, or the buddymoon. Please know that no gift is required. However, if you would like to give us a gift, please use the link below to view our registry.
                    </p>
                    <p>
                        <a href="https://www.hitchd.com/evelynandconnor"  
                            target="_blank"
                            rel="noopener noreferrer"
                            className="yellow btn-2 text-xs-center">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>REGISTRY</a>
                    </p>
                    <p className="pSmall">
                        If you would rather a cash gift in person, there will be a box at the hall. You could also select this option in the registry, or send it directly to evcranston@gmail.com using e-transfer.
                    <br/>
                    <br/>
                        If you would like to bring a physical gift that is appreciated, but we have to drive back to Vancouver, so only small items please.
                        <br/>
                    </p>
                    
                </Col>
                <Col md="1"/>
                <Col md="5">
                    <p>
                     <img src={moreSparkles} style={{width:"50%"}} alt="Sparkles Illustration"/>
                    </p>
                </Col>
                <Col md="1"/>
        </Row>
    </Container>
      
      </div>
    );
  }
}

export default Registry;