import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col} from 'reactstrap';
import monogram from './img/eccs-monogram-horizontal.svg';

import localFun from './img/localfun.jpg';


class LocalFun extends Component {
    
    constructor(props){
        super(props);

        this.state={
        }
    }
    
    render() {
    
  return (
      
    <div className="LocalFun">
      
      <Container className="bottom-spacer">
        <Row>
            <Col xs={{size:8, order: 1}}>
                    <div className="h1-XXL yellow">Local Fun</div>
            </Col>
        </Row>
        <Row>
            <Col xs={{size:10, order: 2, offset: 2}}>
            </Col>
        </Row>
      </Container>
      
    <br /> 

    <Container className="bottom-spacer">      
        <Row>
                <Col md="5">
                    <p>
                      In 2017, Edmonton rebranded from “City of Champions” to “Edmonton.” Which is pretty apt, frankly. Like it, leave it, or stay forever, everyone can admit Alberta&apos;s capital city has a special charm. It’s known for deep freezes, lightning storms, endless sunshine, and dramatic skies, sometimes all before lunchtime.
                    </p>
                </Col>
                <Col md="1"/>
                <Col md="5">
                    <p>
                        Edmonton is home to year around recreation and festivals, and has many attractions such as the river valley parkland, Fort Edmonton Park, and the Royal Alberta Museum. Edmonton is also known for green onion cakes, an oversized mall, and not being Calgary.
                    </p>
                </Col>
                <Col md="1"/>
        </Row>
    </Container>
      
    <Container>
      <Row className="bottom-spacer-x2">
        <Col xs="12">
            <img src={localFun} width="100%" alt="Evelyn Biking in Edmonton"/>
        </Col>
      </Row>
    </Container>
      
    <Container className="bottom-spacer">
      
        <Row className="bottom-spacer">
            <Col xs="6" s="2">
                <h3>Things to do</h3>
            </Col>
            <Col xs="6" s="10"/>
        </Row>
      
        <Row>
            <Col 
                className="bottom-spacer"
                xs={{ size: 10, order: 2, offset: 1}}
                md={{ size: 4, order: 2, offset: 2}}>
                    <h2>Ride the High Level Streetcar</h2>
                    <p>
                        Ride a refurbished 1900s streetcar across the 100 year old High Level Bridge, and get a spectacular view of the city. This historic experience is the highest streetcar river crossing in the world!
                    </p>
                    <a 
                        href="http://www.edmonton-radial-railway.ab.ca/highlevelbridge/" 
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>High Level Steetcar
                    </a>
            </Col>
      
      <Col 
                className="bottom-spacer"
                xs={{ size: 10, order: 2, offset: 1}}
                md={{ size: 4, order: 2, offset:2}}>
                    <h2>Rent a Bike or E-bike</h2>
                    <p>
                    Edmonton has a beautiful and expansive trail system through the river valley - it’s the largest urban parkland in North America! Pedal blissfully uninterrupted for kilometers on car-free trails that wind around the North Saskatchewan River. 
                    </p>
                    <a 
                        href="https://www.edmontonbikerentals.com"    
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>BIKE RENTALS
                    </a>
            </Col>
        
        </Row>
      
        <Row>
          <Col
                className="bottom-spacer"
                xs={{ size: 10, order: 2, offset: 1}}
                md={{ size: 4, order: 2, offset:2}}>
                <h2>Go back in time at Fort Edmonton Park</h2>
                <p>
                Go back in time and discover Edmonton’s early history at the 1846 Hudson’s Bay Fort and walk the streets of 1885! There's a brand new Indigenous exhibit that is amazing.
                </p>
                <a 
                    href="https://www.fortedmontonpark.ca/" 
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{width:"100%"}} 
                    className="yellow btn-2 text-xs-center">
                        <i className="material-icons md-18">arrow_forward</i>
                    <span> </span>FORT EDMONTON PARK
                </a>
            </Col>
      
            <Col    
                className="bottom-spacer" 
                xs={{ size: 10, order: 2, offset: 1}}
                md={{ size: 4, order: 2, offset:2}} >
                    <h2>See Bison at Elk Island National Park</h2>
                    <p>
                        A Canadian safari! This is Canada’s only fenced national park, and it has a higher density of hoofed animals per sq. km than any other wilderness area in the world except the Serengeti! Moose, plains bison, elk, white-tailed deer, beavers, and over 250 species of bird inhabit this beautiful natural area.  
                    </p>
                    <a 
                        href="https://www.pc.gc.ca/en/pn-np/ab/elkisland" 
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>ELK ISLAND
                    </a>
            </Col>
        </Row>
      

     
      
      <Row>
          <Col  
                className="bottom-spacer"
                xs={{ size: 10, order: 2, offset: 1}}
                md={{ size: 4, order: 2, offset:2}}>
                    <h2>Visit the Royal Alberta Museum</h2>
                    <p>
                        Explore Alberta’s stories, from the prehistoric to the modern age. Evelyn’s favourite exhibit: the gemstone gallery. Connor’s favourite exhibit: extinct megafauna. 
                    </p>
                    <a 
                        href="https://royalalbertamuseum.ca/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>ROYAL ALBERTA MUSEUM
                    </a>
            </Col>
      
            <Col    
                className="bottom-spacer"
                xs={{ size: 10, order: 2, offset: 1}}
                md={{ size: 4, order: 2, offset:2}}>
                    <h2>Peruse the Art Gallery</h2>
                    <p>
                        The classic, the weird, and the beautiful. See what’s going on inside this strange and iconic twisted steel building downtown. Note: The AGA and the RAM are within a block of each other. 
                    </p>
                    <a 
                        href="https://www.youraga.ca/" 
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{width:"100%"}} 
                        className="yellow btn-2 text-xs-center">
                            <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>ART GALLERY OF ALBERTA
                    </a>
            </Col>
        </Row>
      
    </Container>
      
    <Container>
      
        <Row className="bottom-spacer">
            <Col xs="6" s="2">
                <h3>Places to eat</h3>
            </Col>
            <Col xs="6" s="10"/>
        </Row>
      
        <Row>
        <Col 
            xs={{size: 10, order: 2, offset: 1}}
            md={{size: 3, order: 1}}>
                <h2>Local Casual Restaurants</h2>
                <ul className="ul-lg">
                    <li>Next Act</li>
                    <li>High Level Diner</li>
                    <li>Sugarbowl</li>
                    <li>Mill Creek Cafe</li>
                    <li>Route 99 Diner</li>
                </ul>
        </Col>
      
        <Col 
            xs={{size: 10, order: 2, offset: 1}}
            md={{size: 3, order: 2}}>
                <h2>Local Coffee</h2>
                <ul className="ul-lg">
                    <li>Remedy Cafe</li>
                    <li>The Carrot Community Arts Coffeehouse</li>
                    <li>Anvil Coffee House</li>
                    <li>Leva Cafe</li>
                    <li>Little Brick Cafe</li>
                </ul>
        </Col>
      
        <Col 
            xs={{size: 10, order: 2, offset: 1}}
            md={{size: 3, order: 1}}>
                <h2>Local Desserts</h2>
                <ul className="ul-lg">
                    <li>Kind Ice Cream</li>
                    <li>Sugared and Spiced</li>
                    <li>La Boule</li>
                    <li>Duchess Bakery</li>
                    <li>Block 1912</li>
                </ul>
        </Col>
      
        </Row>
    </Container>
                
    <Container fluid style={{backgroundColor:"white"}}>
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs={{size:4, offset: 4, order: 2}} md={{size: 2, offset: 5, order: 2}}>
                        <img className="center" style={{width: "70%"}} src={monogram} alt="EC + CS Monogram"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>

      
    </div>
      
    );
  }
}

export default LocalFun;