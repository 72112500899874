import React, {Component} from 'react';
import { Container, Row, Col} from 'reactstrap';
import './App.css';
    
class RSVP extends Component {
    
    constructor(props){
        super(props);

        this.state={
        }
    }
    
    render() {   
        
  return (
        <div className="RSVP">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
      
            <Container style={{marginBottom:"4.25vh"}}>
                <Row>
                    <Col xs="8">
                        <div className="h1-XXL yellow">RSVP</div>
                    </Col>
                    <Col xs="4">
                        
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row>
                    <Col xs="12">
                    <br />
                        <div className="gForm">
                        <iframe title="rsvp" src="https://docs.google.com/forms/d/e/1FAIpQLSdo0_wo3ceP_oJBc9TCRIF4ospS9YZ-2O22IR4HNLKVpOanWQ/viewform?embedded=true" width="100%" height="2600" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
      
      </div>
    );
  }
}

export default RSVP;