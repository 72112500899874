import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col} from 'reactstrap';
import monogram from './img/eccs-monogram-horizontal.svg';
import cocktail from './img/cocktail.svg';
import discoBall from './img/disco-ball.svg';
import {Link} from "react-router-dom";

class Details extends Component {
    
    constructor(props){
        super(props);

        this.state={
            
        }
        this.getConnorEmail = this.getConnorEmail.bind(this);
        this.getEvelynEmail = this.getEvelynEmail.bind(this);
        this.getKateEmail = this.getKateEmail.bind(this);
    }
    
    
        // Email obfuscator script 2.1 by Tim Williams, University of Arizona
        // Random encryption key feature coded by Andrew Moulden
        // This code is freeware provided these four comment lines remain intact
        // A wizard to generate this code is at http://www.jottings.com/obfuscator/
    
        getConnorEmail(){
            var coded = "sKIlzTT5TJA@oYzpQ.s5Y";
            var key = "7xbVyN6wdIPmXgDEBQnMjaeZ4LOk2cr1YtoqHhiT9Wzf3vRC0FsKAGu5USlp8J";
            var shift=coded.length;
            var link="";
            var ltr;
            
              for (var i=0; i<coded.length; i++) {
                if (key.indexOf(coded.charAt(i))===-1) {
                  ltr = coded.charAt(i);
                  link += (ltr);
                }
                else {     
                  ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length;
                  link += (key.charAt(ltr));
                }
              }
        
            return ("<a href='mailto:"+link+"'>Connor</a>");
}
    
        getEvelynEmail(){
            var coded = "BNuHhWYk0W@Q2h7w.u02";
            var key = "7xs3P2ubOB0QDwVNrzFJT5YAMtdX6CpIL1qUHfnyaEiGjk8mcZ4eogKlRvW9hS";
            var shift=coded.length;
            var link="";
            var ltr;
            
              for (var i=0; i<coded.length; i++) {
                if (key.indexOf(coded.charAt(i))===-1) {
                  ltr = coded.charAt(i);
                  link += (ltr);
                }
                else {     
                  ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length;
                  link += (key.charAt(ltr));
                }
              }
        
            return ("<a href='mailto:"+link+"'>Evelyn</a>");
}
    
        getKateEmail(){
            var coded = "P43Ngbangv5N3QQ@N73e4vp.C7e";
            var key = "5duLG238raeqQFRxNwZOokjXspTI91Wyczig46Y0AtSKnmJ7PMfhBEDVUHbClv";
            var shift=coded.length;
            var link="";
            var ltr;
            
              for (var i=0; i<coded.length; i++) {
                if (key.indexOf(coded.charAt(i))===-1) {
                  ltr = coded.charAt(i);
                  link += (ltr);
                }
                else {     
                  ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length;
                  link += (key.charAt(ltr));
                }
              }
        
            return ("<a href='mailto:"+link+"'>Kate</a>");
}

    
    
    render() {
        
  return (
      
    <div className="Details">
      
      <Container className="bottom-spacer">
        <Row>
            <Col xs="8">
                    <div className="h1-XXL yellow">Details</div>
            </Col>
            <Col xs="4">
                <img src={discoBall} style={{width:"100%"}} alt="Disco Ball Illustration"/>
            </Col>
        </Row>
      </Container>
      
    <br /> 
      
<Container className="bottom-spacer">
      
{/* Dress Code */}
  <Row className="bottom-spacer">
    <Col xs="2"/>
    <Col xs="8">
        <h2>To the Nines</h2>
        <p>Let's get dressed up! Semi-formal / cocktail attire would be lovely. Knowing this crowd, you might want to wear or bring shoes you're comfortable dancing in!
        <br/>
        The ceremony and cocktail hour will be held outside on grass, and the reception will be inside the hall. We will be taking a group photo halfway up the folk fest hill.</p>
        </Col>
    <Col xs="2"/>
</Row>
        
{/* Cheers */}
    <Row className="bottom-spacer">
        <Col xs="2"><img className="d-none d-md-block d-lg-block d-xl-block" src={cocktail} style={{maxWidth:"80%", position:"absolute"}} alt="Cocktail Illustration"/></Col>
        <Col xs="8">
            <h2>Cheers!</h2>
            <p>Raise a glass! During cocktail hour we will have signature cocktails and local beer for everyone. Through the night, we will have a loonie bar. Sparkling water and table wine will be provided.</p>
        </Col>
        <Col xs="2"/>
    </Row>
        
{/* Brunch */}
  <Row className="bottom-spacer">
    <Col xs="2"/>
    <Col xs="8">
        <h2>Brunch</h2>
        <p>We will be hosting a brunch at noon on August 30 followed by a backyard jam session at Kate and Jeromes house in Old Strathcona.  Please indicate in the <Link to="/RSVP" className="btn-2">RSVP</Link>if you'd like to attend.</p>
        </Col>
    <Col xs="2"/>
</Row> 
  
{/* After Party */}
    <Row className="bottom-spacer">
        <Col xs="2"/>
        <Col xs="8">
            <h2>After Party</h2>
            <p>Keep your ears open (or ask a member of the wedding party) on the day for details about a spot to keep the night going. </p>
        </Col>
        <Col xs="2"/>
    </Row>
  
{/* Buddymoon */}
    <Row className="bottom-spacer">
        <Col xs="2"/>
        <Col xs="8">
            <h2>Buddymoon</h2>
            <p>We’d like to invite all interested in joining us on a group honeymoon to the area where Connor and Evelyn met and grew up. We’ll be visiting Connor’s family’s guest ranch, nestled in the front ranges of the Rocky mountains, and venturing to Jasper for a few days of hiking, canoeing, and relaxing in nature. Please check out the <Link to="/Buddymoon" className="btn-2">Buddymoon</Link> page for details, and indicate in the <Link to="/RSVP" className="btn-2">RSVP</Link> form if you’d like to attend. Rooms at the Ranch are limited, but camping and hotels in Hinton are an option as well.</p>
        </Col>
        <Col xs="2"/>
    </Row>
  
        
{/* Still Have Questions */}
    <Row className="bottom-spacer">
        <Col xs="2"/>
        <Col xs="8">
            <h2>Still have Questions?</h2>
            <p>Please feel free to email:</p>
            <p className="email-link">
            <a className="yellow btn-2" href={this.getEvelynEmail()}>
                <i className="material-icons md-18">email</i>
                <span> </span>Evelyn
            </a>
            <br/>
            <a className="yellow btn-2" href={this.getKateEmail()}>
                <i className="material-icons md-18">email</i>
                <span> </span>Kate
            </a>
            <br/>
            <a className="yellow btn-2" href={this.getConnorEmail()}>
                <i className="material-icons md-18">email</i>
                <span> </span>Connor
            </a>
            </p>
            </Col>
        <Col xs="2"/>
    </Row>

    </Container>
        
    <Container fluid style={{backgroundColor:"white"}}>
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs={{size:4, offset: 4, order: 2}} md={{size: 2, offset: 5, order: 2}}>
                        <img className="center" style={{width: "70%"}} src={monogram} alt="EC + CS Monogram"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>

    </div>
      
    );
  }
}

export default Details;