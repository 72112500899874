import React, {Component, useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col} from 'reactstrap';

import monogram from './img/eccs-monogram-horizontal.svg';
import kissBike from './img/kissbike-illo.svg';
import eccsPhoto from './img/eccs-photo.jpg';
import tinyHeart from './img/tiny-heart.svg';
import betterTogether from './img/better-together.svg';
import pinwheel from './img/pinwheel.svg';
import sparkles from './img/sparkles.svg';
import moreSparkles from './img/moresparkles.svg';

import dancePhoto from './img/dancephoto.jpg';
import groupPhoto from './img/groupphoto.jpg';
import polaroidProfessional from './img/polaroid-professional.png';
import polaroidPhotobooth from './img/polaroid-photobooth.png';
import polaroidBuddymoon from './img/polaroid-buddymoon.png';
import polaroidGuest from './img/polaroid-guest.png';
import polaroidHilary from './img/polaroid-hilary.png';

import disco from './img/thankyous/BE-disco2.jpg';
import mom from './img/thankyous/BE-mom2.jpg';
import family from './img/thankyous/BE-family.jpg';
import BCGR from './img/thankyous/BCGR.jpg';
import grandma from './img/thankyous/BE-gmajean.jpg';
import CRC from './img/thankyous/BE-CRC.jpg';
import TF from './img/thankyous/TF-gif.gif';
import photographer from './img/thankyous/BE-photographer.jpg';
import borch from './img/thankyous/BE-borch.jpg';
import teacup from './img/thankyous/BE-dance.jpg';
import culina from './img/thankyous/BE-culina.jpg';
import cloverdale from './img/thankyous/BE-cloverdale.jpg';
import cocktails from './img/thankyous/cocktails.gif';
import callia from './img/thankyous/BE-callia.jpg';
import laurel from './img/thankyous/BE-laurel.jpg';
import bridesmaids from './img/thankyous/BE-bridesmaids.jpg';
import beer from './img/thankyous/BE-beer.jpg';
import cake from './img/thankyous/BE-cake.jpg';

const imageArray = [disco, mom, family, BCGR, grandma, CRC, TF, photographer, borch, teacup, culina, cloverdale, cocktails, callia, laurel, bridesmaids, beer, cake];


class NewHome extends Component {
    
    constructor(props){
        
        super(props);

        this.state={ 
            imageToShow: 0,
            image: imageArray[0],
            class: "stickyDiv",
            windowWidth: window.screen.width,
        }
        
//        this.windowResizeSet = this.windowResizeSet.bind(this);
    }
    
    windowResizeSet() {
        this.setState({windowWidth: window.screen.width});
    }  

    componentDidMount(){
        window.addEventListener('resize',this.windowResizeSet.bind,)
    }
    
    componentWillUnmount(){
        window.removeEventListener('resize',this.windowResizeSet.bind,)
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.imageToShow !== this.state.imageToShow) {
                setTimeout(() => {
                    if (prevState.class == "stickyDiv") {
                        this.setState({class: "stickyDiv2",
                                      image: imageArray[this.state.imageToShow]});
                    } else {
                        this.setState({class: "stickyDiv",
                                      image: imageArray[this.state.imageToShow]});
                    }
                }, 1);

            
        }
       
    }
    
    
    render() {
//        const windowResizeSet = this.windowResizeSet();

    
  return (
    <div className="NewHome">
      
{/*Photo*/}
      <Container className="bottom-spacer-x2">
        <Row>
            <Col xs="12">
                <div className="img-hover-zoom">
                    <img src={dancePhoto} style={{width:"100%"}} alt="Evelyn and Connor Dancing"/>
                </div>
            </Col>
        </Row>
      </Container>
      
    <br /> 

{/*Text*/}
    <Container className="bottom-spacer-x2">
        <Row>
            <Col xs={{size: 0}} md={{size: 2, offset:10, order: 2}} className="d-none d-md-block d-lg-block d-xl-block">
                <img src={moreSparkles} style ={{width: "100%"}} alt="Sparkles Icon"/>
            </Col>
        </Row>
        <Row>
            <Col xs="12" md={{size:6, offset: 3, order: 2}}>
                <div className="text-center">
                    <h3>We did it!</h3>
                    <p className="textLG">After 12 years together, 7 years engaged, and 2 delays, Evelyn and Connor are a married couple! This wedding marks a new chapter of our relationship, and a commitment to always be better together, forever.
                    <br/><br/>
                    We want to thank everyone who made our wedding so special. The love and support from family and friends made it everything we could have hoped for and more.
                    </p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={{size: 0}} md={{size: 2}} className="d-none d-md-block d-lg-block d-xl-block">
                <img src={betterTogether} style ={{width: "75%"}} alt="Better Together Icon"/>
            </Col>
        </Row>
      </Container>
      
{/*Polaroid Gallery*/}
    <Container className="bottom-spacer-x2">
        <Row>
            <Col md={{size:4, offset:0}} xs={{size:10, offset: 1}} style={this.state.windowWidth <= 768 ?{marginTop: "20px"}:{}} >
                <a href="https://clients.brittanyesther.com/6ofctul1fzqwh" target="_blank" rel="noopener noreferrer">
                    <img className="polaroidProfessional" src ={polaroidProfessional} alt="Professional Gallery Thumbnail"/>
                    <div className="btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>Professional Photos
                    </div>
                </a>
            </Col>
            <Col md={{size:4, offset:0}} xs={{size:10, offset: 1}} style={this.state.windowWidth <= 768 ?{marginTop: "20px"}:{}} >
                <a href="https://photos.app.goo.gl/fZYnP6B5TXy1GiMC7" target="_blank" rel="noopener noreferrer">
                    <img className="polaroidPhotobooth" src ={polaroidPhotobooth} alt="Photobooth Gallery Thumbnail"/>
                    <div className="btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>Photobooth Photos
                    </div>
                </a>
            </Col>
            <Col md={{size:4, offset:0}} xs={{size:10, offset: 1}} style={this.state.windowWidth <= 768 ?{marginTop: "20px"}:{}} >
                <a href="https://photos.app.goo.gl/L9fU8FvVvYs2G8wG9" target="_blank" rel="noopener noreferrer">
                    <img className="polaroidBuddymoon" src ={polaroidBuddymoon} alt="Buddymoon Gallery Thumbnail"/>
                    <div className="btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>Buddymoon Photos
                    </div>
                    <p className="addYoursLabel">please add yours!</p>
                </a>
            </Col>
        </Row>
        <br/>
        <Row>
           <Col md={{size:4, offset:2}} xs={{size:10, offset: 1}} > 
               <a href="https://photos.app.goo.gl/7ViFNqPT6hzsoHj48" target="_blank" rel="noopener noreferrer">
                    <img className="polaroidGuest" src ={polaroidGuest} alt="Guest Gallery Thumbnail"/>
                    <div className="btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>Wedding Guest Photos
                    </div>
                    <p className="addYoursLabel">please add yours!</p>
                </a>
            </Col>
            <Col md={{size:4, offset:0}} xs={{size:10, offset: 1}} style={this.state.windowWidth <= 768 ?{marginTop: "20px"}:{}} >
                <a href="https://photos.app.goo.gl/qMwLMbXFP9sRjgCZ8" target="_blank" rel="noopener noreferrer">
                    <img className="polaroidHilary" src ={polaroidHilary} alt="Hilary Gallery Thumbnail"/>
                    <div className="btn-2">
                        <i className="material-icons md-18">arrow_forward</i>
                        <span> </span>Hilary's Photos
                    </div>
                </a>
            </Col>
        </Row>
    </Container>

        

{/*Group Photo*/}
    <Container className="bottom-spacer-x2">
        <Row>
            <Col xs="12">
                <img src={groupPhoto} style={{width:"100%"}} alt="Group Photo"/>
            </Col>
        </Row>
    </Container>
      
    <Container className="bottom-spacer-x2">
        <Row>
            <Col md={{size:6, offset: 3, order: 2}} xs="12">
                <div className="text-center">
                    <h3>Thank you!</h3>
                    <p className="textLG">Friends and family traveled from other provinces, other countries, and even from other continents to be there. Weddings are one of the few occasions we gather together in celebration, and it meant a lot that our guests made the effort to be there and make our day amazing. Thank you for being an important part of our life!</p>
                </div>
            </Col>
        </Row>
    </Container>
        
{/*Shoutouts*/}
<Container className="bottom-spacer-x2">
        <Row>
            <Col xs="12">
                    <h2>And a special thank you to...</h2>
            </Col>
        </Row>
        <br/>
        <Row>
            <Col md="7" xs="12">
      
                    {/*Wedding Party*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 0})}>
                        <p className="TY-list"><span className="bold">The wedding party </span> and everyone who contributed in transforming that humble little community hall and empty field into a twinkling pocket of magic at the bottom of the hill. Thank you for all your efforts in set up and clean up!

                        <img
                            src={imageArray[0]}  
                            style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                            alt="Inside of a wedding hall with twinkly lights and a disco ball"
                        /><hr/></p>
                    </div>
                    
      
                    {/*Mom*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 1})}>
                        <p className="TY-list"><span className="bold" >Kate, </span>the one who brought the event all together! Without Kate there would have been no bride, and certainly no wedding. No detail escaped her, and everything went off without a hitch thanks to her amazing organization and execution. Thank you for literally years of planning! Plus, everyone loved the jokes and fortunes.
                        <img
                            src={imageArray[1]}  
                            style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                            alt="Kate and Evelyn portrait"
                        /><hr/></p>
                    </div>

                    {/*Family*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 2})}>
                        <p className="TY-list"><span className="bold" >Family members </span>who helped out, contributed, supported us, and even gave excellent speeches!
                        <img
                            src={imageArray[2]}  
                            style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                            alt="Family portrait"
                        /><hr/></p>
                    </div>
      
                    {/*Black Cat, Hilary, Fran*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 3})}>
                        <p className="TY-list">
                            <a href="http://blackcatguestranch.ca/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">The Black Cat Guest Ranch </span>
                            </a>
                            for welcoming us by hosting the Buddymoon and providing our home base in the mountains. Special thanks to Hilary, Connor, and Fran for being our local guides! It’s been the backdrop to many stories over the years, so it was extra special to introduce our friends to this corner of the world. Our Buddymoon really was the perfect ending to the perfect wedding. We’re looking forward to the reunion in the coming years!
                            <img
                                src={imageArray[3]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Someone riding a horse along a ridge"
                        /><hr/></p>
                    </div>
                    
      
                    {/*Grandma Jean*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 4})}>
                        <p className="TY-list"><span className="bold" >Grandma Jean </span>for making the journey to join us! We loved that you were there!
                            <img
                                src={imageArray[4]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Grandma Jean and Evelyn hugging"
                        /><hr/></p>
                    </div>
      
                    {/*CRC*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 5})}>
                        <p className="TY-list"><span className="bold" >Charlotte, </span>our most official officiant, for her polished prose, perfect for our sweet, secular ceremony. She wrote our ceremony from the heart, and made it so thoughtful and sincere.  And she kept the bad jokes to a minimum, for which we are very grateful.
                            <img
                                src={imageArray[5]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Charlotte at a microphone"
                        /><hr/></p>
                    </div>
      
                    {/*Them Flowers*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 6})}>
                        <p className="TY-list">
                            <a href="https://www.instagram.com/them__flowers/?next=%2Fmrlmn2%2F&hl=en" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Them Flowers </span>
                            </a>
                           for brightening our day with wonderful wildflowers. They were grown from seed and tended with love, until each bloom was picked and thoughtfully arranged. The flowers and their colorful Alberta aesthetic added pure joy to our late-summer wedding day. Each unique arrangment was its own work of art, and each stem felt like a little love letter from the Earth, delivered by Them Flowers.
                            <img
                                src={imageArray[6]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Animated gif of small flower arrangements"
                        /><hr/></p>
                    </div>
      
                    {/*Brittany Esther Photography*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 7})}>
                        <p className="TY-list">
                            <a href="https://brittanyesther.com/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Brittany Esther Photography </span>
                            </a>
                            for thoughtfully documenting all the big events and little moments that made the day so special. We’ll frame the and display the portraits of course, but some of our favourite shots will live in the albums, ready to be shared with a story. There’s Ruby leading Grandma down the aisle, us running through the spray park, Pixie getting tangled in tulle, Kate riding her bike down the street to the Hall, Jerome grooving to Stayin’ Alive, and countless more. The photos have a classic, gentle, and natural quality that makes everything and everyone shine in their best light. She let the day unfold naturally, capturing each scene with expert craft and care. We will treasure the photos forever, and are so grateful to get to relive the day through her lens. 
                            <img
                                src={imageArray[7]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="A black and white blurry photo of Connor and Evelyn going up the stairs"
                        /><hr/></p>
                    </div>
                
                    {/*Borch Brothers Band*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 8})}>
                        <p className="TY-list">
                            <a href="https://theborchbrothers.ca/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Borch Brothers Band </span>
                            </a>
                            for creating a lively ambience and an unforgettable soundtrack to the ceremony and cocktail hour. We go way back with these talented gentlemen, and were so happy that they could share their creative talents with everyone.
                            <img
                                src={imageArray[8]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="The Borch Brothers smiling"
                        /><hr/></p>
                    </div>
      
                    {/*Teacup DJ*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 9})}>
                        <p className="TY-list">
                            <a href="https://www.teacupdj.com/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Teacup DJ </span>
                            </a>
                            for keeping us dancing all night long! The music makes the reception, and Danica makes the music! Thanks to her tunes, costumes, and photobooth we had the night of our lives!
                            <img
                                src={imageArray[9]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Dancing on the dancefloor"
                        /><hr/></p>
                    </div>
      
                    {/*Culina Catering*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 10})}>
                        <p className="TY-list">
                            <a href="https://www.culinafamily.com/culina-catering/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Culina Catering </span>
                            </a>
                            for excellent food! Connor had just 3 requests for the wedding — good friends, lots of fun, and great food — and Culina really delivered with creative cuisine and professional panache!
                            <img
                                src={imageArray[10]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Shelley and Kyle taking food off a plate"
                        /><hr/></p>
                    </div>
                    
      
                    {/*Cloverdale Community Hall*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 11})}>
                        <p className="TY-list"><span className="bold">Cloverdale Community Hall </span>for providing space for all the festivities. Nestled at the bottom of a big hill in one of Edmonton’s most charming neighborhoods, it was a wonderful spot for a celebration — and looked great with 500 meters of twinkly lights!
                            <img
                                src={imageArray[11]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Evelyn and Connor walking towards the hall"
                        /><hr/></p>
                    </div>
      
                    {/*Bartenders*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 12})}>
                        <p className="TY-list"><span className="bold">Our three wonderful volunteer bartenders  </span>for (pro)serving our signature cocktails, and keeping the party going!
                            <img
                                src={imageArray[12]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Animated gif of three glasses clinking"
                        /><hr/></p>
                    </div>
      
                    {/*Callia Flowers*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 13})}> 
                        <p className="TY-list"><span className="bold">Callia flowers </span>for our beautiful bouquets!
                        <img
                            src={imageArray[13]}  
                            style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                            alt="Four bouquets lined up on a porch"
                        />
                        <hr/></p>
                    </div>

                    {/*Laurel's Flowers*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 15})}>
                        <p className="TY-list">
                            <a href="https://www.shoplaurelsonwhyte.com/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Laurel's Florals </span>
                            </a>
                            for the groom’s boutonniere, which Pixie really enjoyed!
                            <img
                                src={imageArray[14]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Pixie biting Connor's flowers"
                            />  
                            <hr/></p>
                    </div>
      
                    {/*Ponytails & Horseshoes*/}  
                    <div onMouseEnter={() => this.setState({imageToShow: 15})}>
                        <p className="TY-list">
                            <a href="https://www.poho.ca/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Ponytails and Horseshoes </span>
                            </a>
                            for making us all look extra spiffy!
                            <img
                                src={imageArray[15]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Fran, Evelyn, Robin, and Dara smiling"
                        /><hr/></p>
                    </div>
                        
      
                    {/*Alley Kat Brewing*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 16})}>
                        <p className="TY-list">
                            <a href="https://www.alleykatbeer.com/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Alley Kat Beer </span>
                            </a>
                            for excellent local beer from friendly people.
                            <img
                                src={imageArray[16]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Fran holding a beer and smiling"
                        /><hr/></p>
                    </div>
      
                    {/*Sugared and Spiced*/}
                    <div onMouseEnter={() => this.setState({imageToShow: 17})}>
                        <p className="TY-list">
                            <a href="https://www.sugaredandspiced.ca/" target="_blank" rel="noopener noreferrer">
                                <span className="TY-link">Sugared and Spiced Cakes </span>
                            </a>
                            for the pink raspberry lemon cake of my dreams!
                            <img
                                src={imageArray[17]}  
                                style={this.state.windowWidth <= 768 ?{width: "100%", borderRadius: "10px", marginTop: "20px"}:{display:"none"} } 
                                alt="Pink wedding cake on a table with flowers"
                        /><hr/></p>
                    </div>
                    
            </Col>

            <Col md={{size:5, offset: 0, order: 0}} style={this.state.windowWidth<=768?{display:"none"}:{display:"block"}}>
                
                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==0?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[0]}  
                            style={{width: "100%", align:"right", borderRadius: "10px"}} 
                            alt="Inside of a wedding hall with twinkly lights and a disco ball"
                        />
                </div>

                <div 
                    className={this.state.class}
                    style= {this.state.imageToShow==1?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[1]}
                            style={{width: "100%", align:"right", borderRadius: "10px"}}
                            alt="Portrait of Kate and Evelyn"
                        />
                </div>

                <div 
                    className={this.state.class}
                    style= {this.state.imageToShow==2?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[2]}
                            style={{width: "100%", align:"right", borderRadius: "10px"}}
                            alt="Family portrait"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==3?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[3]}  
                            style={{width: "100%", align:"right", borderRadius: "10px"}} 
                            alt="Someone on horseback riding along a trail"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==4?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[4]}  
                            style= {{width: "100%", align:"right", borderRadius: "10px"}} 
                            alt="Evelyn and Grandma Jean hugging"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==5?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[5]}  
                            style={{width: "100%", align:"right", borderRadius: "10px"}} 
                            alt="Charlotte standing at a microphone"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==6?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[6]}  
                            style={{width: "75%", align:"center", borderRadius: "10px"}} 
                            alt="Animated gif of flowers in a vase"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==7?{display:"block"}:{display:"none"}}>
                        <img
                            className="img-center"
                            src={imageArray[7]}  
                            style={{width: "75%", align:"center", borderRadius: "10px"}} 
                            alt="A black and white blurry photo of Evelyn and Connor going up the stairs"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==8?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[8]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="The Borch Brothers playing music and smiling"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==9?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[9]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="People on the dancefloor"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==10?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[10]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="Two people eating appetizers off a tray"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==11?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[11]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="Evelyn and Connor walking into the Hall"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==12?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[12]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="Animated gif of people clinking glasses"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==13?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[13]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="Four bouquets in vases of water lined up on a porch"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==14?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[14]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="Pixie biting off Connor's corsage"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==15?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[15]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="4 girls standing on a porch"
                        />
                </div>

                <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==16?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[16]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="Someone holding a beer and smiling"
                        />
                </div>
                  <div 
                    className={this.state.class} 
                    style= {this.state.imageToShow==17?{display:"block"}:{display:"none"}}>
                        <img 
                            className="img-center"
                            src={imageArray[17]}  
                            style={{width: "100%", align:"center", borderRadius: "10px"}} 
                            alt="A pink cake on a table with flowers"
                        />
                </div>

        </Col>
    </Row>
</Container>


{/*Footer*/}
<Container fluid style={{backgroundColor:"white"}}>
    <Row>
        <Col xs={{size:4, offset: 4, order: 2}} md={{size: 2, offset: 5, order: 2}}>
            <img src={monogram} alt="EC + CS Monogram"/>
        </Col>
    </Row>
</Container>

{/*Illustrations*/}


</div>
      
    );
  }
}

export default NewHome;