import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col} from 'reactstrap';
import bikeIcon from './img/bike-icon.png';

class SaveTheDate extends Component {
    
    constructor(props){
        super(props);

        this.state={
        }
    }
    
    render() {
                 
        
  return (
      <Container fluid>
                      <Row>
                          <Col xs="12" style={{textAlign:"center", marginTop:"4.25vh"}}>
                            <h3>Save the Date</h3>
                            <h1>
                                AUGUST 29, 2020
                            </h1>
                            <h6>
                                Cloverdale Community Hall
                                <br />
                                Edmonton Alberta
                            </h6>
                            <img src={bikeIcon} style={{width: 80, height: 40}} alt="bikeIcon"/>
                        </Col>
                    </Row>

                        <Row>
                            <Col xs="12">
                                <br />
                                <div className="gForm">
                                    <iframe title="std" src=" https://docs.google.com/forms/d/1zHTZyYgSc3eUi4SHJ_wHhmxOvywkj5DuL1ovI1a0QLk/edit" width="100%" height="2600" frameborder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
      
          );
  }
}

export default SaveTheDate;