import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Collapse} from 'reactstrap';
import monogram from './img/eccs-monogram-horizontal.svg';
import rockiesImg from './img/rockies.jpg';
import rainbowImg from './img/rainbow.jpg';
import eccsPhoto2 from './img/eccs-photo-2.jpg';
import solomanImg from './img/soloman.jpg';
import sulpherSkyline from './img/sulpher-skyline.jpg';
import {Link} from "react-router-dom";



class Buddymoon extends Component {
    
    constructor(props){
        super(props);

        this.state={
            isOpen: false
        }
    }
    
    render() {
        
  return (
      
    <div className="Buddymoon">
      
      <Container style={{marginBottom:"4.25vh"}}>
        <Row>
            <Col xs={{size:8, order: 1}}>
                    <div className="h1-XXL yellow">Buddymoon</div>
            </Col>
        </Row>
        <Row>
            <Col xs={{size:10, order: 2, offset: 2}}>
            </Col>
        </Row>
      </Container>
      
    <br /> 

    <Container style={{marginBottom:"4.25vh"}}>      
        <Row style={{marginBottom:"3rem"}}>
                <Col md="5">
                    <p>
                        What's a Buddymoon? It's when everyone comes along for a few more days of fun following the main event! Evelyn and Connor met in Hinton Alberta, and their friendship blossomed over a mutual love of the outdoors. Connor grew up at the Black Cat Guest Ranch, which is located minutes from Jasper National Park, with a breathtaking view of the mountains right out from your rooms. We will have several planned activities, please feel welcome to join some or all of them. Itinerary may change based on weather and group interests. 
                    </p>
                </Col>
                <Col md="1"/>
                <Col md="5">
                    <p>
                        The Black Cat has been in Connor’s family for almost 50 years, and Connor spent his entire childhood there. The ranch has an extensive trail network, incredible natural scenery, and a comfortable common area where you can unwind with games, puzzles, reading, and just relaxing. We’ll be touring some of the area’s favourite attractions like Maligne Canyon, Mount Solomon, and Miette Hot Springs. Join us and see the setting for Connor’s famous stories in person!
                    </p>
                </Col>
                <Col md="1"/>
        </Row>
    </Container>
      
    <Container style={{marginBottom:"4.25vh"}}>
      <Row>
        <Col xs="12">
            <img src={rainbowImg} width="100%" alt="Rocky Mountain view"/>
        </Col>
      </Row>
    </Container>
      
    <Container>
        <Row>
            <Col s="2" xs="4">
                <h3>Tuesday</h3>
                <p className="caption-text">
                    Sept 6
                </p>
            </Col>
            <Col s="10" xs="8"/>
        </Row>
      
        <Row style={{marginBottom:"4.25vh"}}>
            <Col xs={{ size: 10, order: 2, offset: 2}} md={{ size: 5, order: 2, offset: 2}}>
                <h2>Arrive at Destination</h2>
                <p>
                    Hinton is a 3 hour drive from Edmonton, and the Ranch is 20 minutes further. Please indicate if you'll be attending and if you'll be driving on the <Link to="/RSVP" className="btn-2">RSVP</Link> form, and we will work out co-ordinating ridesharing.
                </p>
                <p  
                    style={{width:"100%", textAlign:"left"}} 
                    className="yellow btn-2"
                    onClick={()=>{this.setState({isOpen:!this.state.isOpen})}}>
                        <i className="material-icons md-18">keyboard_arrow_down</i>
                    <span> </span> Accomodation Options
                </p>

                <Collapse isOpen={this.state.isOpen}>
                    <Row>
                        <Col xs={{ size: 12}}>
                            <ul className="ul-sm">
                                <li>
                                    Black Cat Guest Ranch: The ranch where Connor grew up.  Up to 15 rooms available. Up to 4 per room. Please indicate in the <a href="/rsvp" className="btn-2 yellow">RSVP</a> if you would like to share a room. Rooms will probably run out quickly.
                                </li>
                                <li>
                                    Free camping at the Black Cat.
                                </li>
                                <li>
                                    Overlander Mountain Lodge: 30 minute drive to Black Cat, 45 minute drive to Jasper townsite.
                                </li>
                                <li>
                                    Jasper East Cabins: 25 minute drive to Black Cat, 40 minute drive to Jasper townsite. 
                                </li>
                                <li>
                                    Hotels in Hinton and Jasper
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Collapse>

            </Col>
        </Row>
      
        <Row>
             <Col md={{ size: 5, order: 2, offset:2}} xs={{ size: 10, order: 2, offset: 2}}>
                    <h2>Afternoon Hike</h2>
                    <p className="caption-text">Solomon Mountain<br/>
                        10 KM, 610 Metres (5 hours)
                    </p>
                    <p>
                        This a beautiful trail that begins right at the Black Cat, and offers views of the Brule range and Athabasca Lake. Just don't eat the soapberries when Connor offers.
                    </p>
            </Col>
        </Row>
        
        <Row className="bottom-spacer">
            <Col xs={{ size: 10, order: 2, offset: 2}} md={{ size: 8, order: 2, offset: 2}}>
                <img src={solomanImg} style={{width:"100%"}} alt="View from Soloman Mountain"/>
            </Col>
        </Row>
      
        <Row style={{marginBottom:"4.25vh"}}>
             <Col md={{ size: 5, order: 2, offset:2}} xs={{ size: 10, order: 2, offset: 2}}>
                    <h2>Chill out</h2>
                    <p>
                        We can either visit Folding Mountain Brewing for some local Jasper beers, or kick back with a good book, a drink, a boardgame, and enjoy the view out the front window of the Ranch.  
                    </p>
            </Col>
        </Row>
    </Container>

    <Container>
        <Row>
            <Col s="2" xs="4">
                <h3>Wednesday</h3>
                <p className="caption-text">
                    Sept 7
                </p>
            </Col>
            <Col s="10" xs="8"/>
        </Row>
      
        <Row style={{marginBottom:"4.25vh"}}>
            <Col xs={{ size: 10, order: 2, offset: 2}} md={{ size: 5, order: 2, offset: 2}}>
                <h2>Canoe Maligne Lake</h2>
                <p>
                    A truly spectacular experience. The further you paddle, the more magnificent the views get, as jagged peaks close in around the crystal turquoise water.
                </p>
                <a 
                    href="https://www.wildcurrentoutfitters.ca/canoe-rentals" 
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{width:"100%"}} 
                    className="yellow btn-2 text-xs-center">
                        <i className="material-icons md-18">arrow_forward</i>
                    <span> </span>Canoe Rental
                </a>
            </Col>
        </Row>
      
        <Row style={{marginBottom:"4.25vh"}}>
             <Col md={{ size: 5, order: 2, offset:2}} xs={{ size: 10, order: 2, offset: 2}}>
                    <h2>Explore Maligne Canyon</h2>
                    <p>
                        Peer down into this deep rift in the earth, and watch the waterfalls carve out the canyon walls, as they’ve done for millenia. Appropriate for all levels of hiking ability. 
                    </p>
            </Col>
        </Row>

        <Row style={{marginBottom:"4.25vh"}}>
             <Col md={{ size: 5, order: 2, offset:2}} xs={{ size: 10, order: 2, offset: 2}}>
                    <h2>Visit Jasper</h2>
                        <p>Some great things to do in Jasper:</p>
                        <ul className="ul-sm">
                            <li>Pretend to be fancy and stroll around Jasper Park Lodge</li>
                            <li>Dip in the crystal clear water of Lake Annette (weather permitting! Or not, if you're brave.)</li>
                            <li>Look for elk on the side of the highway</li>
                            <li>Visit the island at Pyramid Lake</li>
                        </ul>
            </Col>
        </Row>                                                          
    </Container>
                                                                                
    <Container>
        <Row>
            <Col s="2" xs="4">
                <h3>Thursday</h3>
                <p className="caption-text">
                    Sept 8
                </p>
            </Col>
            <Col s="10" xs="8"/>
        </Row>
      
        <Row>
            <Col xs={{ size: 10, order: 2, offset: 2}} md={{ size: 5, order: 2, offset: 2}}>
                <h2>Hike Sulpher Skyline</h2>
                <p className="caption-text">
                        7.7 KM, 649 Metres
                </p>
                <p>
                    Sulphur Skyline offers some of Jasper's most scenic views, and as the name suggests, some its more interesting smells as well. Sulphur Skyline starts by Miette hot springs, winding up the valley past the old hot springs complex and several pungent natural outflows, before breaking out above the treeline relatively close to the summit. Anyone tired of hiking can turn around before the climb and spend some extra time soaking the hot springs.
                </p>
            </Col>
        </Row>
                                                                                
        <Row style={{marginBottom:"4.25vh"}}>
            <Col xs={{ size: 10, order: 2, offset: 2}} md={{ size: 8, order: 2, offset: 2}}>
                <img src={eccsPhoto2} style={{width:"100%"}} alt="View from Sulpher Skyline"/>
            </Col>
        </Row>
      
        <Row style={{marginBottom:"4.25vh"}}>
             <Col md={{ size: 5, order: 2, offset:2}} xs={{ size: 10, order: 2, offset: 2}}>
                    <h2>Soak in Miette Hot Springs</h2>
                    <p>
                        Nothing heals sore muscles like mountain mineral water, and nothing cures a foggy head like those cold pools! 
                    </p>
            </Col>
        </Row>
                                                                                
                                                                                   
        <Row style={{marginBottom:"4.25vh"}}>
             <Col md={{ size: 5, order: 2, offset:2}} xs={{ size: 10, order: 2, offset: 2}}>
                    <h3>See you there!</h3>
                    <p>
                        We hope you will be able to join us for the Buddymoon! We're very excited to show our friends around one of Canada's most famous wilderness treasures. Rooms at the ranch will go quickly, so please <Link to="/RSVP" className="btn-2">RSVP</Link> soon if you want to stay there. 
                    </p>
            </Col>
        </Row>
                                                  
    </Container>
      
    
                
    <Container fluid style={{backgroundColor:"white"}}>
        <Row>
            <Col xs="12">
                <Row>
                    <Col xs={{size:4, offset: 4, order: 2}} md={{size: 2, offset: 5, order: 2}}>
                        <img className="center" style={{width: "70%"}} src={monogram} alt="EC + CS Monogram"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>

      
    </div>
      
    );
  }
}

export default Buddymoon;